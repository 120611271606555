import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "./App.scss";
import chmln from "@chamaeleonidae/chmln";
import { NavigatingRoutes } from "./routes/Routes";
// import { useChameleonModalContext } from "./Context/ChameleonModal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { deleteAllCookies } from "./utils/Utils";
// import {
//   setIsFHSReset,
//   setShowGaugeChart,
//   setSkipFHSAPICall,
// } from "./rtk-query/features/dashboard/financialHealthScore/financialHealthScoreAnalysisSlice";
// import { opportunitiesAnalysisSlice } from "./rtk-query/features/dashboard/opportunities/opportunitiesAnalysisSlice";
// import { secondaryConsumerAnalysisSlice } from "./rtk-query/features/dashboard/secondaryConsumer/secondaryConsumerAnalysisSlice";
// import { missedRevenueAnalysisSlice } from "./rtk-query/features/dashboard/missedRevenue/missedRevenueAnalysisSlice";
// import { bondScoreAnalysisSlice } from "./rtk-query/features/dashboard/bondScore/bondScoreAnalysisSlice";
// import { topCompetitorsAnalysisSlice } from "./rtk-query/features/dashboard/topCompetitors/topCompetitorsAnalysisSlice";

const App = () => {
  // const chameleonContext = useChameleonModalContext();
  const authReducer = useSelector((state) => state.authReducer);
  // const dispatch = useDispatch();

  // window.onload = function (event) {
  //   if (
  //     window.performance &&
  //     window.performance.navigation.type ===
  //       window.performance.navigation.TYPE_RELOAD
  //   ) {
  //     window.history.pushState(null, null, location.href);
  //     window.history.back();
  //     window.history.forward();
  //   }
  //   dispatch(setSkipFHSAPICall(false));
  //   dispatch(setIsFHSReset(true));
  //   dispatch(setShowGaugeChart(false));
  //   dispatch(opportunitiesAnalysisSlice.util.resetApiState());
  //   dispatch(secondaryConsumerAnalysisSlice.util.resetApiState());
  //   dispatch(missedRevenueAnalysisSlice.util.resetApiState());
  //   dispatch(bondScoreAnalysisSlice.util.resetApiState());
  //   dispatch(topCompetitorsAnalysisSlice.util.resetApiState());
  // };

  // window.onbeforeunload = (event) => {
  //   // !window.location.href.includes("bankperformancemetrics") && window.history.pushState({}, "", "/user/clearsession");
  //   if (
  //     (window.performance &&
  //       window.performance.navigation.type ===
  //         window.performance.navigation.TYPE_BACK_FORWARD) ||
  //     (window.performance &&
  //       window.performance.navigation.type ===
  //         window.performance.navigation.TYPE_RELOAD) ||
  //     (window.performance &&
  //       window.performance.navigation.type ===
  //         window.performance.navigation.TYPE_NAVIGATE)
  //   ) {
  //     window.history.pushState(null, null, location.href);
  //     window.history.back();
  //     window.history.forward();
  //     window.history.pushState({}, "", "/user/clearsession");
  //   } else {
  //     window.history.pushState(null, null, location.href);
  //     window.history.back();
  //     window.history.forward();
  //   }
  // };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-HRN7TTHQ9N");
    }

    return deleteAllCookies();
  }, []);

  // useEffect(() => {
  //   const handleKeyPress = (event) => {
  //     if (event.key === "Escape") {
  //       chameleonContext.setChameleonValue(false);
  //     }
  //   };

  //   // Add event listener when the component mounts
  //   document.addEventListener("keydown", handleKeyPress);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (authReducer?.isLogin) {
  //     const chmlnFlag = process.env.REACT_APP_CHAMELEON_IS_ENABLE;

  //     // console.log(process.env.REACT_APP_CHAMELEON_IS_ENABLE);
  //     if (chmlnFlag === "true") {
  //       //initialize chameleon
  //       chmln.init(process.env.REACT_APP_CHAMELEON_API_Key);
  //       localStorage.setItem("chameleonInitialized", "true");
  //       //send user data to chameleon
  //       chmln.identify(
  //         `${authReducer.userData.orguid}${authReducer.userData.useruid}`,
  //         {
  //           // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")/* chameleon.io user identification and data */
  //           email: authReducer.userData.userName,
  //           role: authReducer.userData?.role, // RECOMMENDED, email is used as the key to map user data for integrations
  //         },
  //       );
  //     }
  //   }
  // }, [authReducer]);

  return <NavigatingRoutes />;
};

export default App;
