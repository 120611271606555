import logo_black_img from "../../assets/logos/logo_bondai_167x48.svg";
import download_blue_img from "../../assets/images/download_blue_img.png";
import download_white_img from "../../assets/images/download_white_img.png";
// import faq_collapse_icon from "../../assets/images/faq_collapse_icon.png"
// import faq_expand_icon from "../../assets/images/faq_expand_icon.png"
// import faq_hubspot_icon from "../../assets/images/faq_hubspot_icon.png"
// import favicon from "../../assets/images/favicon.png"
import gauge_blue from "../../assets/images/gauge_blue.svg";
import gauge_white from "../../assets/images/gauge_white.svg";
import trend_blue from "../../assets/images/trend_blue.svg";
import trend_white from "../../assets/images/trend_white.svg";
import Filter from "../../assets/images/Filter.png";
import download_pdf from "../../assets/images/downloadPdf.svg";
import Filter_selected from "../../assets/images/Filter_selected.png";
// import get_pro_link from "../../assets/images/get_pro_link.png"
// import get_pro_link_popup from "../../assets/images/get_pro_link_popup.png"
// import GhostConsumer from "../../assets/images/GhostConsumer.png"
// import Gradient from "../../assets/images/Gradient.png"
// import graph_icon_auto_loan from "../../assets/images/graph_icon_AutoLoans.svg"
// import graph_icon_business_loan from "../../assets/images/graph_icon_BusinessLoans.svg"
// import graph_icon_credit_card from "../../assets/images/graph_icon_CreditCard.svg"
// import graph_icon_mortgage from "../../assets/images/graph_icon_Mortgage.svg"
// import graph_icon_personal_loan from "../../assets/images/graph_icon_PersonalLoan.svg"
// import graph_icon_primary_consumer from "../../assets/images/graph_icon_Primary_Consumer.svg"
// import graph_icon_secondary_consumers from "../../assets/images/graph_icon_secondaryConsumer.svg"
// import graph_icon_student_loan from "../../assets/images/graph_icon_StudentLoans.svg"
// import grey_lock_img from "../../assets/images/grey_lock_img.png"
// import grey_lock_img_old from "../../assets/images/grey_lock_img-old.png"
import Health_analysis_img from "../../assets/images/health_analysis_img.png";
// import health_score from "../../assets/images/health_score.png"
import hidePwd from "../../assets/images/hidePwd.png";
import history_download_btn from "../../assets/images/history_download_btn.png";
// import icon_bank from "../../assets/images/icon_bank.png"
// import side_Panel_First from "../../assets/images/side_Panel_First.png"
// import side_Panel_Second from "../../assets/images/side_Panel_Second.png"
// import side_Panel_Third from "../../assets/images/side_Panel_Third.png"
// import side_Panel_Expand from "../../assets/images/side_Panel_Expand.svg"
// import side_Panel_Collapse from "../../assets/images/side_Panel_Collapse.svg"
// import data_Mapping_Active_Icon from "../../assets/images/data_Mapping_Active_Icon.svg"
// import get_Results_Active_Icon from "../../assets/images/get_Results_Active_Icon.svg"
// import icon_bank_grey from "../../assets/images/icon_bank_grey.png"
// import icon_faq from "../../assets/images/icon_faq.png"
// import icon_faq_grey from "../../assets/images/icon_faq_grey.png"
// import icon_history from "../../assets/images/icon_history.png"
// import icon_history_grey from "../../assets/images/icon_history_grey.png"
// import icon_home from "../../assets/images/icon_home.png"
// import icon_home_grey from "../../assets/images/icon_home_grey.png"
// import icon_home_old from "../../assets/images/icon_home-old.png"
// import icon_logout from "../../assets/images/icon_logout.png"
// import icon_notification from "../../assets/images/icon_notification.png"
// import icon_notification_bank_health_score from "../../assets/images/icon_notification_bank_health_score.png"
// import icon_notification_data_upload_successfully from "../../assets/images/icon_notification_data_upload_successfully.png"
// import icon_notification_old_2 from "../../assets/images/icon_notification-old (2).png"
// import icon_notification_old from "../../assets/images/icon_notification-old.png"
// import icon_print from "../../assets/images/icon_print.png"
// import icon_pro from "../../assets/images/icon_pro.png"
// import icon_pro_grey from "../../assets/images/icon_pro_grey.png"
// import icon_pro_grey_old from "../../assets/images/icon_pro_grey-old.png"
// import icon_pro_old_2 from "../../assets/images/icon_pro-old (2).png"
// import icon_pro_old from "../../assets/images/icon_pro-old.png"
// import icon_setting from "../../assets/images/icon_setting.png"
// import icon_tnc from "../../assets/images/icon_tnc.png"
// import icon_tnc_grey from "../../assets/images/icon_tnc_grey.png"
// import icon_upload from "../../assets/images/icon_upload.png"
// import icon_upload_grey from "../../assets/images/icon_upload_grey.png"
// import Insight from "../../assets/images/Insight.png"
// import invest_result from "../../assets/images/invest_result.png"
// import invest_result1 from "../../assets/images/invest_result1.png"
// import landing_page from "../../assets/images/landing_page.png"
// import Landing_page_for_mob from "../../assets/images/Landing_page_for_mob.png"
// import Landing_page_for_tab from "../../assets/images/Landing_page_for_tab.png"
// import landing_page_old from "../../assets/images/landing_page-old.png"
// import lock from "../../assets/images/lock.png"
import missed_revenue from "../../assets/images/missed_revenue_trial.svg";
import consumer_behavior from "../../assets/images/consumer_behaviour_trial.svg";
import modal_close_btn from "../../assets/images/modal_close_btn.png";
// import mp_icon_Auto_Loan from "../../assets/images/mp_icon_Auto Loan.svg"
import mp_icon_Auto_Loan from "../../assets/images/auto_loan_icon.svg";
import mp_icon_Secondary_Consumers from "../../assets/images/secondary_consumer_icon.svg";
import mp_icon_Primary_Consumers from "../../assets/images/primary_consumer_icon.svg";
// import mp_icon_Online_Services from "../../assets/images/mp_icon_Online_Services.svg"
// import mp_icon_Gasoline_Fuel from "../../assets/images/mp_icon_Gasoline_Fuel.svg"
// import mp_icon_Healthcare_Medical from "../../assets/images/mp_icon_Healthcare_Medical.svg"
// import mp_icon_Travel from "../../assets/images/mp_icon_Travel.svg"
// import mp_icon_Restaurants_Dining from "../../assets/images/mp_icon_Restaurants_Dining.svg"
// import mp_icon_Clothing_Shoes from "../../assets/images/mp_icon_Clothing_Shoes.svg"
// import mp_icon_Groceries from "../../assets/images/mp_icon_Groceries.svg"
// import mp_icon_Electronics from "../../assets/images/mp_icon_Electronics.svg"
// import mp_icon_Entertainment from "../../assets/images/mp_icon_Entertainment.svg"
import mp_icon_Business_Loan from "../../assets/images/business-loan-icon.svg";
// import mp_icon_Business_Loan from "../../assets/images/mp_icon_Business Loan.svg"
// import mp_icon_Credit_Cards from "../../assets/images/mp_icon_Credit Cards.png"
// import mp_icon_Credit_Card from "../../assets/images/mp_icon_Credit Cards.svg"
import mp_icon_Credit_Card from "../../assets/images/credit_card_icon.svg";
// import mp_icon_creditcard_old from "../../assets/images/mp_icon_creditcard-old.png"
// import mp_icon_Insurance from "../../assets/images/mp_icon_Insurance.svg"
// import mp_icon_Gifts from "../../assets/images/mp_icon_Gifts.svg"
// import mp_icon_Personal_Care from "../../assets/images/mp_icon_Personal_Care.svg"
// import mp_icon_Utilities from "../../assets/images/mp_icon_Utilities.svg"
// import mp_icon_insurance_old from "../../assets/images/mp_icon_insurance-old.png"
// import mp_icon_insurance_old1 from "../../assets/images/mp_icon_insurance-old1.png"
import mp_icon_Consumer_Loan from "../../assets/images/consumer_loan_icon.svg";
// import mp_icon_Consumer_Loan from "../../assets/images/mp_icon_Consumer Loans.svg"
// import mp_icon_Mortgage from "../../assets/images/mp_icon_Mortgage.svg"
import mp_icon_Mortgage from "../../assets/images/mortgage_icon.svg";
// import mp_icon_Personal_Loan from "../../assets/images/mp_icon_Personal Loan.svg"
import mp_icon_Personal_Loan from "../../assets/images/personal_loan_icon.svg";
// import mp_icon_Savings from "../../assets/images/mp_icon_savings.png"
// import mp_icon_savings_old from "../../assets/images/mp_icon_savings-old.png"
// import mp_icon_Student_Loan from "../../assets/images/mp_icon_Student Loan.svg"
import mp_icon_Student_Loan from "../../assets/images/student_loan_icon.svg";
// import mp_icon_studentloan_old from "../../assets/images/mp_icon_studentloan-old.png"
// import mp_icon_Icon_default from "../../assets/images/mp_icon_Icon-default.svg";
import mp_icon_Icon_default from "../../assets/images/Default_Merchants.svg";
import dashboard_upgrade_lock from "../../assets/images/upgrade_lock.svg";
// import opportunities_upgrade from "../../assets/images/opportunities_upgrade.svg";
// import Nav_Home_black2x from "../../assets/images/Nav_Home black@2x.png"
// import need_more_details_popup from "../../assets/images/need_more_details_popup.png"
// import new_user_graph from "../../assets/images/new_user_graph.png"
// import persona_chart from "../../assets/images/persona_chart.png"
// import pro_true_user_gender_selected from "../../assets/images/pro_true_user_gender_selected.png"
// import pro_true_user_gender_unselected from "../../assets/images/pro_true_user_gender_unselected.png"
// import pro_true_user_usersAvg_age_selected from "../../assets/images/pro_true_user_usersAvg_age_selected.png"
// import pro_true_user_usersAvg_age_unselected from "../../assets/images/pro_true_user_usersAvg_age_unselected.png"
// import pro_true_user_usersRetaillvsBuisness_selected from "../../assets/images/pro_true_user_usersRetaillvsBuisness_selected.png"
// import pro_true_user_usersRetaillvsBuisness_unselected from "../../assets/images/pro_true_user_usersRetaillvsBuisness_unselected.png"
// import product_icon_file_upload from "../../assets/images/product_icon_file_upload.png"
// import product_icon_upload from "../../assets/images/product_icon_upload.png"
// import Results_Cross_Sale from "../../assets/images/Results-Cross_Sale.png"
// import Results_missing_products from "../../assets/images/Results-missing_products.png"
// import Results_Primary_consumer from "../../assets/images/Results-Primary_consumer.png"
import Search from "../../assets/images/Search.png";
import showPwd from "../../assets/images/showPwd.png";
// import SignIn from "../../assets/images/SignIn.png"
// import SignIn_1 from "../../assets/images/SignIn-1.png"
// import SignIn_2 from "../../assets/images/SignIn-2.png"
// import speedometer from "../../assets/images/speedometer.png"
// import Top3_persona_chart from "../../assets/images/Top3_persona_chart.png"
// import tp_icon_Borrower from "../../assets/images/tp_icon_Borrower.svg"
// import tp_icon_Earner from "../../assets/images/tp_icon_Earner.svg"
// import tp_icon_Planner from "../../assets/images/tp_icon_Planner.svg"
// import tp_icon_Saver from "../../assets/images/tp_icon_Saver.svg"
// import tp_icon_Spender from "../../assets/images/tp_icon_Spender.svg"
// import tp_icon_NA from "../../assets/images/tp_icon_NA.svg"
import tp_icon_Borrower from "../../assets/images/Borrower.svg";
import tp_icon_Earner from "../../assets/images/Earner.svg";
import tp_icon_Planner from "../../assets/images/Planner.svg";
import tp_icon_Saver from "../../assets/images/Saver.svg";
import tp_icon_Spender from "../../assets/images/Spender.svg";
// import tp_icon_NA from "../../assets/images/tp_icon_NA.svg"
// import trial_box from "../../assets/images/trial_box.png"
// import true_user_gender_selected from "../../assets/images/true_user_gender_selected.png"
// import true_user_gender_unselected from "../../assets/images/true_user_gender_unselected.png.png"
// import true_user_usersAvg_age_selected from "../../assets/images/true_user_usersAvg_age_selected.png"
// import true_user_usersAvg_age_unselected from "../../assets/images/true_user_usersAvg_age_unselected.png"
// import true_user_usersRetaillvsBuisness_selected from "../../assets/images/true_user_usersRetaillvsBuisness_selected.png"
// import true_user_usersRetaillvsBuisness_unselected from "../../assets/images/true_user_usersRetaillvsBuisness_unselected.png"
// import upload_Consumer_data from "../../assets/images/upload_Consumer_data.png"
// import upload_data_in_loader from "../../assets/images/upload_data_in_loader.png"
// import upload_Product_data from "../../assets/images/upload_Product_data.png"
// import white_lock from "../../assets/images/white_lock.png"
// import white_lock_old from "../../assets/images/white_lock-old.png"
import animation_loader from "../../assets/images/animation_loader.gif";
// import avatar from "../../assets/images/avatar.png"
// import avatar_old from "../../assets/images/avatar-old.png"
// import Avg_bond_score from "../../assets/images/Avg_bond_score.png"
// import avtar from "../../assets/images/avtar.png"
// import behavioural_stability from "../../assets/images/behavioural_stability.png"
// import Blue_Lock from "../../assets/images/Blue_Lock.png"
// import Blue_Lock_old from "../../assets/images/Blue_Lock-old.png"
// import cheating_Consumer from "../../assets/images/Cheating_Consumer .png"
// import consumer_bond_score from "../../assets/images/consumer_bond_score.png"
// import consumer_icon_file_upload_folder from "../../assets/images/consumer_icon_file_upload_folder.png"
// import consumer_icon_upload from "../../assets/images/consumer_icon_upload.png"
// import CrossBtn from "../../assets/images/CrossBtn.png"
import dashboard_bond_score from "../../assets/images/dashboard_bond_score.svg";
import dashboard_missed_revenue from "../../assets/images/dashboard_missed_revenue.svg";
// import data_mapping_bg from "../../assets/images/data_mapping_bg.png"
import downArrow from "../../assets/images/downArrow.png";
// import alert_close from "../../assets/images/notifications/alert-close.png"
import upload from "../../assets/images/notifications/Upload.png";
import uploadNew from "../../assets/images/Upload.svg";
import trend from "../../assets/images/trend.svg";
import trendHover from "../../assets/images/trendHover.svg";
import infoIcon from "../../assets/images/InfoCircle.svg";
// import iconTrend from "../../assets/images/icon-trend.png"
// import icon_Trend from "../../assets/images/icon_trend.svg"
import chevron_down from "../../assets/images/chevron-down.svg";
// import iconTrendOld from "../../assets/images/icon_trend-old.png"
// import iconDetail from "../../assets/images/icon_detail.svg"
// import backButton from "../../assets/images/back_button.png"
import backArrowFilter from "../../assets/images/icon_Back_arrow.svg";
// import backArrow from "../../assets/images/backArrow.png"
import backArrowNew from "../../assets/images/backArrow.svg";
// import chipListCrossBtn from "../../assets/images/chipListCrossBtn.png"
import filterexpandicon from "../../assets/images/filter_expand_icon.png";
// import frontArrow from "../../assets/images/frontArrow.png"
import frontArrowNew from "../../assets/images/frontArrow.svg";
// import hoverfrontArrow from "../../assets/images/hoverFrontArrow.png"
import hoverfrontArrowNew from "../../assets/images/hoverFrontArrow.svg";
// import DangerCircle from "../../assets/images/DangerCircle-1.png"
// import hoverBackArrow from "../../assets/images/hoverBackArrow.png"
import hoverBackArrowNew from "../../assets/images/hoverBackArrow.svg";
import hoverUploadIcon from "../../assets/images/hoverUploadIcon.png";
import hoverUploadIconNew from "../../assets/images/hoverUploadIcon.svg";
// import mpiconBusinessLoan from "../../assets/images/mp_icon_Business Loan.png"
// import zonefilter from "../../assets/images/zone_filter.png"
// import zonefilterblue from "../../assets/images/zone_filter_blue.png"
import filter_colapse from "../../assets/images/filter_collapse_icon.png";
// import graph_icon_consumer_loan from "../../assets/images/graph_icon_consumerLoans.svg"
// import BG_TC_First from "../../assets/images/BG_TC_First.png"
// import BG_TC_Second_old from "../../assets/images/BG_TC_Second-old.png"
// import BG_TC_Second from "../../assets/images/BG_TC_Second.png"
// import BG_TC_Third from "../../assets/images/BG_TC_Third.png"
// import BG_TM_First from "../../assets/images/BG_TM_First.png"
// import BG_TM_Third from "../../assets/images/BG_TM_Third.png"
// import BG_TM_Second from "../../assets/images/BG_TM_Second.png"
// import file_Upload_Icon from "../../assets/images/file_Upload_Icon.svg"
// import data_Mapping_Icon from "../../assets/images/data_Mapping_Icon.svg"
// import get_Results_Icon from "../../assets/images/get_Results_Icon.svg"
// import icon_default_bank from "../../assets/images/icon_default_bank.svg"
import icon_default_bank from "../../assets/images/bank-default-icon.svg";
import icon_detail_TC from "../../assets/images/icon_detail_TC.svg";
// import datecard from "../../assets/images/datecard.png"
import fileupload from "../../assets/images/faq_icon_fileUpload.png";
import financialHealthScore from "../../assets/images/faq_icon_financialHealthScore.png";
import privacyPolicy from "../../assets/images/faq_icon_privacyPolicy.png";
import templateUsage from "../../assets/images/faq_icon_templateUsage.png";
import hubspot from "../../assets/images/faq_icon_hubspot.png";
import savedFilter from "../../assets/images/savedfilter.svg";
import editsvg from "../../assets/images/edit.svg";
import editCollectionsvg from "../../assets/images/icon_filter_edit.svg";
import deletesvg from "../../assets/images/delete.svg";
import deletefilter from "../../assets/images/filterdelete.svg";
// import noAnalysisImage from "../../assets/images/no-analysis-image.svg"
import noDataFound from "../../assets/images/noDataFound.svg";
// import filterEdit from "../../assets/images/filterEdit.svg"
import filterSaveChanges from "../../assets/images/filterSaveChanges.svg";
import listDark from "./icon_list_dark.svg";
import listLight from "./icon_list_white.svg";
import tileDark from "./icon_layout_dark.svg";
import tileLight from "./icon_layout_light.svg";
import largeBlockDark from "./icon_largetile_dark.svg";
import largeBlockLight from "./icon_largeblock_white.svg";
import icons_Total_consumer from "../../assets/images/Icons_Total consumer.svg";
import icons_total_accounts from "../../assets/images/Icons_total accounts.svg";
import icon_total_transactions from "../../assets/images/Icon_total transactions.svg";
import icon_Date_range from "../../assets/images/Icon_Date range.svg";
import FA_icons_arrow_up from "../../assets/images/FA_icons_arrow_up.svg";
import FA_icons_arrow_down from "../../assets/images/FA_icons_arrow_down.svg";
import icon_role_star from "./Icons_star.svg";
// import dp_missing from "./dp_missing.svg"
// import dp_consumer from "./dp_consumer.svg"
// import dp_account from "./dp_account.svg"
// import dp_transaction from "./dp_transaction.svg"
// import dp_product from "./dp_product.svg"

// import FU_consumerdata from "./FU_consumerdata.svg"
// import FU_productdata from "./FU_productdata.svg"
// import FU_accountdata from "./FU_accounts.svg"
// import FU_transactiondata from "./FU_transactiondata.svg"
import FU_cross from "./FU_cross.svg";
// import FU_Successful from "./FU_Successful.svg"
// import FU_ErrorFile from "./FU_ErrorFile.svg"

import uplift_loans from "./uplift_loan.svg";
import automated_marketing from "./automated_marketing_big_img.svg";
import beat_the_competition from "./Beat_the_comp.svg";
import boost_deposits from "./boost_deposits.svg";
import card_expand_btn from "./card_expand_btn.svg";
import scheduled_campaign_img from "./scheduled-campaign.svg";
import active_campaign_img from "./active_campaign.svg";

// import DM_data_upload_template_product_details from "./productdata_dm.svg"
// import DM_data_upload_template_consumer_details from "./consumerdata_dm.svg"
// import DM_missing from "./missing_dm.svg"
// import DM_data_upload_template_accounts_to_products_details from "./accountdata_dm.svg"
// import DM_data_upload_template_transaction_details from "./transactiondata_dm.svg"
// import FU_data_upload_template_accounts_to_products_details from "./FU_data-upload-template_accounts-to-products-details.svg"
// import FU_data_upload_template_consumer_details from "./FU_data-upload-template_consumer-details.svg"
// import FU_data_upload_template_product_details from "./FU_data-upload-template_product-details.svg"
// import FU_data_upload_template_transaction_details from "./FU_data-upload-template_transaction-details.svg";
// import Template_one from "./tempplateone.png";
// import Template_two from "./templatetwo.png";
// import Email_template_1 from "./email_template_1.png"
// import Email_template_3 from "./email_template_3.jpg"
// import Email_template_4 from "./emal_template_4.png"
// import FHS_bankscohort from "./bankscohort.svg"
import FHS_cohortaverage from "./cohortaverage.svg";
import FHS_institution from "./FHS_institution.svg";
import FHS_efficiencyratio from "./efficiencyratio.svg";
import FHS_AdjAllowanceForLoanLossesOvernplsToTotalLoansRatio from "./FHS_AdjAllowanceForLoanLossesOvernplsToTotalLoansRatio.svg";
import FHS_grossnpa from "./grossnpa.svg";
import FHS_Growth_Score from "./growth.svg";
import FHS_Financial_Health_Score from "./bank.svg";
// import FHS_Fiantial_Health_Score from "./bank.svg"
import RS_ReturnOnAverageAssets from "./RS_ReturnOnAverageAssets.svg";
import RS_NPAToTotalLoans from "./RS_NPAToTotalLoansRatio.svg";
import RS_AdjNPAToTotalAssests from "./RS_AdjNPAToTotalAssests.svg";
import GS_CostOfInterbankdeposits from "./GS_CostOfInterbankdeposits.svg";

import FHS_Stability_Score from "./riskscore.svg";
import FHS_cohortaverage_score from "./cohort_avg_score.svg";
import FHS_netintrestmargin from "./netintrestmargin.svg";
import FHS_netnpa from "./netnpa.svg";
import FHS_percentile from "./fhs_percentile.svg";
import FHS_totalassetsyeargrowth from "./totalassetsyeargrowth.svg";
import FHS_totaldepositsyeargrowth from "./totaldepositsyeargrowth.svg";
import FHS_totalloansyeargrowth from "./totalloansyeargrowth.svg";
import FHS_returnonaverageassets from "./returnonaverageassets.svg";
import FHS_NPAToTotalLoansRatio from "./FHS_NetWorthToTotalAssetsRatio.svg";
import FHS_YieldCost from "./FHS_DelinquentLoansToNetWorthRatio.svg";
import GS_CostOfTimeDeposits from "./GS_PercentageChangeInTotalShareDrafts.svg";
import GA_PercentChangeInTotalAssets from "./GS_YieldOnAverageEarningAssets.svg";

// import Simulated_branch_exp from "./simulated_branch_exp.svg"
// import Simulated_branch_back from "./simulated_branch_back.svg"
// import Simulated_bank_modal from "./simulatedbank_modal.svg"

//Chatbot icon
import bont_bot_icon_from from "./Bond_Bot_Icon_Front.png";
import bont_bot_icon_back from "./Bond_Bot_Icon_Back.png";

//Email Campaign icons
import icon_reviews_pending from "./time_half_past.svg";
import icon_approved_campaign from "./mail_favourite.svg";
import icon_ongoing_campaign from "./mail_arrow_right.svg";
import icon_drafted_capmaign from "./group_33.svg";
import icon_deleted_campaign from "./trash_white.svg";
import icon_completed_campaign from "./tick_double.svg";

import icon_users from "./users.svg";
import icon_mail_fail from "./mail_fail.svg";
import icon_mail_block from "./mail_block.svg";

// import list_icon_view_details from "./computer.svg"
// import list_icon_send_test_mail from "./send_test_mail.svg"
// import list_icon_approve from "./mail_favourite_list.svg"
import list_icon_eye from "./mail_eye_list.svg";
import list_icon_trash from "./mail_trash_list.svg";
import list_icon_edit_grey from "./mail_edit_icon.svg";
import list_icon_speed_test from "./mail_icon_speed_test.svg";
import list_icon_edit from "./edit_list.svg";
import list_icon_delete from "./trash.svg";

// import speedtest from "./speedtest.svg"

import success_popup_icon from "../../assets/images/Success.svg";
import error_icon from "../../assets/images/error_icon.svg";
// import alert_popup_icon from '../../assets/images/Alert.svg';
import new_template_icon from "../../assets/images/post_add.svg";

import RE_Recipients_icon from "../../assets/images/RE_Recipients_icon.svg";
import RE_TotalConsumer_icon from "../../assets/images/RE_TotalConsumer_icon.svg";
import RE_Subject_icon from "../../assets/images/RE_Subject_icon.svg";
import RE_TemplateName_icon from "../../assets/images/RE_TemplateName_icon.svg";
import RE_SendSchedule_icon from "../../assets/images/RE_SendSchedule_icon.svg";
import RE_Status_icon from "../../assets/images/RE_Status_icon.svg";
import RE_CancelButton_icon from "../../assets/images/RE_CancelButton_icon.svg";
// import secondary_consumers_upgrade from "../../assets/images/secondary_consumers_upgrade.svg";
// import missing_products_upgrade from "../../assets/images/missing_products_upgrade.svg";
// import ghost_consumers_upgrade from "../../assets/images/ghost_consumers_upgrade.svg";
// import top_competitors_upgrade from "../../assets/images/top_competitors_upgrade.svg";
// import top_merchants_upgrade from "../../assets/images/top_merchants_upgrade.svg";
import merchant_default_icon_upgrade from "../../assets/images/merchant_default_icon.svg";
import growth_upgrade_default from "../../assets/images/growth_upgrade_default.svg";
// import growth_upgrade from "../../assets/images/growth_upgrade.svg";
// import efficiency_ratio_upgrade from "../../assets/images/efficiency_ratio_upgrade.svg";
import missed_revenue_upgrade from "../../assets/images/missed_revenue_upgrade.svg";
// import bond_score_upgrade from "../../assets/images/bond_score_upgrade.svg";
// import financial_health_score_upgrade from "../../assets/images/financial_health_score_upgrade.svg";
// import consumer_demographics_upgrade from "../../assets/images/consumer_demographics_upgrade.svg";
// import top_personas_upgrade from "../../assets/images/top_personas_upgrade.svg";
import icon_upgrade_campaign from "../../assets/images/icon_upgrade_campaign.svg";
import trend_upgrade from "../../assets/images/trend_upgrade.svg";
import export_upgrade from "../../assets/images/export_upgrade.svg";
// import white_upgrade_lock from '../../assets/images/white_upgrade_lock.svg';
import contact_sales_team_upgrade from "../../assets/images/contact_sales_team.svg";
import contact_sales_team_success_upgrade from "../../assets/images/contact_sales_team_success.svg";
import automated_marketing_upgrade from "../../assets/images/automated_marketing_upgrade.svg";
import uplift_loans_upgrade from "../../assets/images/uplift_loans_upgrade.svg";
import beat_the_competition_upgrade from "../../assets/images/beat_the_competition_upgrade.svg";
import boost_deposits_upgrade from "../../assets/images/boost_deposits_upgrade.svg";
import consumer_behavior_upgrade from "../../assets/images/consumer_behaviour_upgrade.svg";
import FHS_red_arrow_down from "../../assets/images/FHS_red_arrow_down.svg";
import FHS_green_arrow_up from "../../assets/images/FHS_green_arrow_up.svg";
// import FHS_red_arrow_up from '../../assets/images/FHS_red_arrow_up.svg';
// import FHS_green_arrow_down from '../../assets/images/FHS_green_arrow_down.svg';
import mobile_static_img from "../../assets/images/mobile_static_img.svg";
// import notification from  "../../assets/images/notification.svg";
// import new_avatar from "../../assets/images/avatar.svg";
import toggle_grid_view_blue from "../../assets/images/toggle-grid-view-blue.svg";
import toggle_grid_view_white from "../../assets/images/toggle-grid-view-white.svg";
import toggle_tile_view_blue from "../../assets/images/toggle-tile-view-blue.svg";
import toggle_tile_view_white from "../../assets/images/toggle-tile-view-white.svg";
import summary_view_white from "../../assets/images/summary_view_white.svg";
import summary_view_blue from "../../assets/images/summary_view_blue.svg";
import expanded_view_white from "../../assets/images/expanded_view_white.svg";
import expanded_view_blue from "../../assets/images/expanded_view_blue.svg";
// import maximize from "../../assets/images/maximize.svg"
import sample_template_one from "../../assets/images/sample-template-one-new.svg";
import sample_template_two from "../../assets/images/sample-template-two.png";
import sample_template_three from "../../assets/images/sample-template-three.png";

import icon_auto_loan from "../../assets/images/icon_auto_loan.svg";
import icon_credit_card from "../../assets/images/icon_credit_card.svg";
import icon_student_loan from "../../assets/images/icon_student_loan.svg";
import icon_business_loan from "../../assets/images/icon_business_loan.svg";
import icon_mortgage from "../../assets/images/icon_mortgage.svg";
import icon_consumer_loan from "../../assets/images/icon_consumer_loan.svg";
import icon_personal_loan from "../../assets/images/icon_personal_loan.svg";

import icon_primary_consumer_with_dda from "../../assets/images/primary_consumer_with_dda.svg";
import icon_primary_consumer_without_dda from "../../assets/images/primary_consumer_without_dda.svg";
import icon_secondary_consumer_with_dda from "../../assets/images/secondary_consumer_with_dda.svg";
import icon_secondary_consumer_without_dda from "../../assets/images/secondary_consumer_without_dda.svg";

// import automated_marketing_img from "../../assets/images/automatedMarketingImg.svg"
import campaign_pii_status from "../../assets/images/campaign_pii_status.jpg";
import no_image from "../../assets/images/no-image.svg";
import cohort_icon from "../../assets/images/cohort-icon.svg";
import cohort_average from "../../assets/images/cohortaverage.svg";
import cohortremoveicon from "../../assets/images/cohortremoveicon.svg";
import cohortcloseicon from "../../assets/images/cohortcloseicon.svg";
import cohortsorticon from "../../assets/images/cohort-sort.svg";
import cohortediticon from "../../assets/images/cohort_edit.svg";
import cohortdeleteicon from "../../assets/images/cohort_delete.svg";
import cohortNoData from "../../assets/images/cohort_no_data.svg";

import power_button from "../../assets/images/power_button.svg";
import power_button_blue from "../../assets/images/power_button_blue.svg";

import icons_privacy_policy from "../../assets/images/icons_privacy_policy.svg";
import icons_privacy_policy_blue from "../../assets/images/icons_privacy_policy_blue.svg";

import icons_settings from "../../assets/images/icons_settings.svg";
import icons_settings_bule from "../../assets/images/icons_settings_bule.svg";

import total_assets_icon from "./total_assets_icon.svg";
import deposit_growth_icon from "./deposit_growth_icon.svg";
import loan_growth_icon from "./loan_growth_icon.svg";
import efficiency_ratio_icon from "./efficiency_ratio_icon.svg";
import net_interest_income_nii_icon from "./net_interest_income_nii_icon.svg";
import net_interest_margin_nim_icon from "./net_interest_margin_nim_icon.svg";
import gross_npa_icon from "./gross_npa_icon.svg";
import npa_to_total_loans_ratio_icon from "./npa_to_total_loans_ratio_icon.svg";
import cohort_average_icon from "./cohort_average_icon.svg";
import cohort_institution from "./cohort_institution.svg";
import cohort_percentile from "./cohort_percentile.svg";
import cohort_avg from "./cohort_avg.svg";
import asset_ratio from "./asset_ratio.svg";
import net_operating_income from "./net_operating_income.svg";
import return_on_equity from "./return_on_equity.svg";
import bulb_fhs_detail from "../../assets/images/bulb_fhs_detail.svg";
import dollaricon from "../../assets/images/dollaricon.svg";
import arrowside from "../../assets/images/arrow_side.svg";
import emailCancel from "../../assets/images/email-cancel.svg";
import thumbsUp from "../../assets/images/thumbsup.svg";
import thumbsDown from "../../assets/images/thumbsdown.svg";
import downloadIcon from "../../assets/images/downloadicon.svg";
import insights_icon_1 from "../../assets/images/insights_icon_1.svg";
import insights_icon_2 from "../../assets/images/insights_icon_2.svg";
import insights_lock_icon from "../../assets/images/insights_lock_icon.svg";
import insights_icon_shortterm from "../../assets/images/insights_icon_shortterm.svg";
import insights_icon_midterm from "../../assets/images/insights_icon_midterm.svg";
import insights_icon_longterm from "../../assets/images/insights_icon_longterm.svg";
import insights_icon_recommendation from "../../assets/images/insights_icon_recommendation.svg";
import insights_icon_problem from "../../assets/images/insights_icon_problem.svg";
import insights_icon_rational from "../../assets/images/insights_icon_rational.svg";
import insights_icon_weak_load from "../../assets/images/insights_icon_weak_load.svg";
import insights_icon_nplms from "../../assets/images/insights_icon_nplms.svg";
import insights_popup_bgimg from "../../assets/images/insights_popup_bgimg.svg";
import deposit from "../../assets/images/deposit.svg";

const exportimages = {
  bulb_fhs_detail,
  consumer_behavior,
  mp_icon_Primary_Consumers,
  mp_icon_Secondary_Consumers,
  error_icon,
  campaign_pii_status,
  // automated_marketing_img,
  sample_template_one,
  sample_template_two,
  sample_template_three,
  toggle_tile_view_white,
  toggle_tile_view_blue,
  toggle_grid_view_white,
  toggle_grid_view_blue,
  // Simulated_bank_modal,
  // Simulated_branch_back,
  // Simulated_branch_exp,
  // FU_data_upload_template_accounts_to_products_details,
  // FU_data_upload_template_consumer_details,
  // FU_data_upload_template_product_details,
  // FU_data_upload_template_transaction_details,
  // DM_data_upload_template_accounts_to_products_details,
  // DM_data_upload_template_consumer_details,
  // DM_data_upload_template_product_details,
  // DM_data_upload_template_transaction_details,
  // DM_missing,
  // Template_two,
  // Template_one,
  // dp_account,
  // dp_consumer,
  // Email_template_1,
  // Email_template_3,
  // Email_template_4,

  // dp_missing,
  // dp_transaction,
  // dp_product,
  icon_role_star,
  listDark,
  listLight,
  tileDark,
  tileLight,
  largeBlockDark,
  largeBlockLight,
  logo_black_img,
  noDataFound,
  // filterEdit,
  filterSaveChanges,
  // noAnalysisImage,
  deletefilter,
  editsvg,
  editCollectionsvg,
  deletesvg,
  savedFilter,
  hubspot,
  templateUsage,
  privacyPolicy,
  financialHealthScore,
  // file_Upload_Icon,
  // data_Mapping_Icon,
  // data_Mapping_Active_Icon,
  // get_Results_Active_Icon,
  // get_Results_Icon,
  // side_Panel_Collapse,
  // side_Panel_Expand,
  fileupload,
  // datecard,
  // iconTrendOld,
  // icon_Trend,
  chevron_down,
  // BG_TC_First,
  // BG_TC_Second_old,
  // BG_TC_Second,
  // BG_TC_Third,
  // BG_TM_First,
  // BG_TM_Second,
  // BG_TM_Third,
  icon_default_bank,
  icon_detail_TC,
  // graph_icon_consumer_loan,
  // hoverfrontArrow,
  hoverfrontArrowNew,
  // backButton,
  // backArrow,
  backArrowNew,
  backArrowFilter,
  // chipListCrossBtn,
  filterexpandicon,
  filter_colapse,
  // frontArrow,
  frontArrowNew,
  // DangerCircle,
  // hoverBackArrow,
  hoverBackArrowNew,
  hoverUploadIcon,
  // mpiconBusinessLoan,
  // side_Panel_First,
  // side_Panel_Second,
  // side_Panel_Third,
  // zonefilter,
  // zonefilterblue,
  // alert_close,
  download_blue_img,
  download_white_img,
  // faq_collapse_icon,
  // faq_expand_icon,
  // faq_hubspot_icon,
  // favicon,
  Filter,
  Filter_selected,
  // get_pro_link,
  // get_pro_link_popup,
  // GhostConsumer,
  // Gradient,
  // graph_icon_auto_loan,
  // graph_icon_business_loan,
  // graph_icon_credit_card,
  // graph_icon_mortgage,
  // graph_icon_personal_loan,
  // graph_icon_primary_consumer,
  // graph_icon_secondary_consumers,
  // graph_icon_student_loan,
  // grey_lock_img,
  // grey_lock_img_old,
  Health_analysis_img,
  // health_score,
  hidePwd,
  history_download_btn,
  // icon_bank,
  // icon_bank_grey,
  // icon_faq,
  // icon_faq_grey,
  // icon_history,
  // icon_history_grey,
  // icon_home,
  // icon_home_grey,
  // icon_home_old,
  // icon_logout,
  // icon_notification,
  // icon_notification_bank_health_score,
  // icon_notification_data_upload_successfully,
  // icon_notification_old_2,
  // icon_notification_old,
  // icon_print,
  // icon_pro,
  // icon_pro_grey,
  // icon_pro_grey_old,
  // icon_pro_old_2,
  // icon_pro_old,
  // icon_setting,
  // icon_tnc,
  // icon_tnc_grey,
  // icon_upload,
  // icon_upload_grey,
  // Insight,
  // iconDetail,
  // invest_result,
  // invest_result1,
  // landing_page,
  // Landing_page_for_mob,
  // Landing_page_for_tab,
  // landing_page_old,
  // lock,
  missed_revenue,
  modal_close_btn,
  mp_icon_Auto_Loan,
  // mp_icon_Healthcare_Medical,
  // mp_icon_Online_Services,
  // mp_icon_Restaurants_Dining,
  // mp_icon_Gasoline_Fuel,
  // mp_icon_Travel,
  // mp_icon_Clothing_Shoes,
  // mp_icon_Electronics,
  // mp_icon_Entertainment,
  // mp_icon_Gifts,
  // mp_icon_Personal_Care,
  // mp_icon_Groceries,
  // mp_icon_Utilities,
  mp_icon_Business_Loan,
  mp_icon_Credit_Card,
  // mp_icon_creditcard_old,
  // mp_icon_Insurance,
  // mp_icon_insurance_old,
  // mp_icon_insurance_old1,
  mp_icon_Consumer_Loan,
  mp_icon_Mortgage,
  mp_icon_Personal_Loan,
  // mp_icon_Savings,
  // mp_icon_savings_old,
  mp_icon_Student_Loan,
  // mp_icon_studentloan_old,
  mp_icon_Icon_default,
  // Nav_Home_black2x,
  // need_more_details_popup,
  // new_user_graph,
  // persona_chart,
  // pro_true_user_gender_selected,
  // pro_true_user_gender_unselected,
  // pro_true_user_usersAvg_age_selected,
  // pro_true_user_usersAvg_age_unselected,
  // pro_true_user_usersRetaillvsBuisness_selected,
  // pro_true_user_usersRetaillvsBuisness_unselected,
  // product_icon_file_upload,
  // product_icon_upload,
  // Results_Cross_Sale,
  // Results_missing_products,
  // Results_Primary_consumer,
  Search,
  showPwd,
  trend_blue,
  trend_white,
  gauge_white,
  gauge_blue,
  // SignIn,
  // SignIn_1,
  // SignIn_2,
  // speedometer,
  // Top3_persona_chart,
  tp_icon_Borrower,
  tp_icon_Earner,
  tp_icon_Planner,
  tp_icon_Saver,
  tp_icon_Spender,
  // tp_icon_NA,
  // trial_box,
  // true_user_gender_selected,
  // true_user_gender_unselected,
  // true_user_usersAvg_age_selected,
  // true_user_usersAvg_age_unselected,
  // true_user_usersRetaillvsBuisness_selected,
  // true_user_usersRetaillvsBuisness_unselected,
  // upload_Consumer_data,
  // upload_data_in_loader,
  // upload_Product_data,
  // white_lock,
  // white_lock_old,
  animation_loader,
  download_pdf,
  // avatar,
  // avatar_old,
  // Avg_bond_score,
  // avtar,
  // behavioural_stability,
  // Blue_Lock,
  // Blue_Lock_old,
  // cheating_Consumer,
  // consumer_bond_score,
  // consumer_icon_file_upload_folder,
  // consumer_icon_upload,
  // CrossBtn,
  dashboard_bond_score,
  dashboard_missed_revenue,
  // data_mapping_bg,
  downArrow,
  trend,
  trendHover,
  upload,
  infoIcon,
  //   iconTrend,
  uploadNew,
  hoverUploadIconNew,
  icons_Total_consumer,
  icons_total_accounts,
  icon_total_transactions,
  icon_Date_range,
  FA_icons_arrow_up,
  FA_icons_arrow_down,
  // FU_consumerdata,
  // FU_productdata,
  // FU_accountdata,
  // FU_transactiondata,
  FU_cross,
  // FHS_Fiantial_Health_Score,
  // FU_Successful,
  // FU_ErrorFile,
  FHS_Financial_Health_Score,
  // FHS_bankscohort,
  FHS_cohortaverage,
  FHS_institution,
  FHS_cohortaverage_score,
  FHS_efficiencyratio,
  FHS_AdjAllowanceForLoanLossesOvernplsToTotalLoansRatio,
  RS_ReturnOnAverageAssets,
  RS_NPAToTotalLoans,
  RS_AdjNPAToTotalAssests,
  GS_CostOfInterbankdeposits,
  FHS_grossnpa,
  FHS_Growth_Score,
  FHS_netintrestmargin,
  FHS_netnpa,
  FHS_percentile,
  FHS_returnonaverageassets,
  FHS_NPAToTotalLoansRatio,
  FHS_YieldCost,
  GS_CostOfTimeDeposits,
  GA_PercentChangeInTotalAssets,
  FHS_Stability_Score,
  FHS_totalassetsyeargrowth,
  FHS_totaldepositsyeargrowth,
  FHS_totalloansyeargrowth,
  bont_bot_icon_from,
  bont_bot_icon_back,
  icon_reviews_pending,
  icon_approved_campaign,
  icon_ongoing_campaign,
  icon_drafted_capmaign,
  icon_deleted_campaign,
  icon_completed_campaign,
  // list_icon_view_details,
  // list_icon_approve,
  list_icon_eye,
  list_icon_trash,
  list_icon_edit,
  list_icon_speed_test,
  list_icon_edit_grey,
  list_icon_delete,
  // list_icon_send_test_mail,
  // speedtest,
  icon_users,
  icon_mail_fail,
  icon_mail_block,
  success_popup_icon,
  // alert_popup_icon,
  new_template_icon,
  RE_Recipients_icon,
  RE_TotalConsumer_icon,
  RE_Subject_icon,
  RE_TemplateName_icon,
  RE_SendSchedule_icon,
  RE_Status_icon,
  RE_CancelButton_icon,
  dashboard_upgrade_lock,
  // opportunities_upgrade,
  // secondary_consumers_upgrade,
  // missing_products_upgrade,
  // ghost_consumers_upgrade,
  // top_competitors_upgrade,
  // top_merchants_upgrade,
  merchant_default_icon_upgrade,
  growth_upgrade_default,
  // growth_upgrade,
  // efficiency_ratio_upgrade,
  missed_revenue_upgrade,
  // bond_score_upgrade,
  automated_marketing_upgrade,
  uplift_loans_upgrade,
  consumer_behavior_upgrade,
  beat_the_competition_upgrade,
  card_expand_btn,
  scheduled_campaign_img,
  active_campaign_img,
  // consumer_demographics_upgrade,
  icon_upgrade_campaign,
  // top_personas_upgrade,
  trend_upgrade,
  export_upgrade,
  // white_upgrade_lock,
  // financial_health_score_upgrade,
  contact_sales_team_upgrade,
  contact_sales_team_success_upgrade,
  uplift_loans,
  automated_marketing,
  beat_the_competition,
  boost_deposits,
  boost_deposits_upgrade,
  FHS_red_arrow_down,
  FHS_green_arrow_up,
  // FHS_red_arrow_up,
  // FHS_green_arrow_down,
  mobile_static_img,
  //   notification,
  // new_avatar,
  summary_view_white,
  summary_view_blue,
  expanded_view_white,
  expanded_view_blue,
  // maximize,
  icon_auto_loan,
  icon_personal_loan,
  icon_credit_card,
  icon_student_loan,
  icon_mortgage,
  icon_business_loan,
  icon_consumer_loan,
  icon_primary_consumer_with_dda,
  icon_primary_consumer_without_dda,
  icon_secondary_consumer_with_dda,
  icon_secondary_consumer_without_dda,
  no_image,
  cohort_icon,
  cohort_average,
  cohortremoveicon,
  cohortsorticon,
  cohortediticon,
  cohortdeleteicon,
  cohortNoData,
  power_button,
  power_button_blue,
  icons_privacy_policy,
  icons_privacy_policy_blue,
  icons_settings,
  icons_settings_bule,
  total_assets_icon,
  deposit_growth_icon,
  loan_growth_icon,
  efficiency_ratio_icon,
  net_interest_income_nii_icon,
  net_interest_margin_nim_icon,
  gross_npa_icon,
  npa_to_total_loans_ratio_icon,
  cohort_average_icon,
  cohort_percentile,
  cohort_avg,
  cohort_institution,
  asset_ratio,
  net_operating_income,
  return_on_equity,
  dollaricon,
  arrowside,
  emailCancel,
  thumbsUp,
  thumbsDown,
  downloadIcon,
  insights_icon_1,
  insights_icon_2,
  insights_lock_icon,
  insights_icon_shortterm,
  insights_icon_midterm,
  insights_icon_longterm,
  insights_icon_recommendation,
  insights_icon_problem,
  insights_icon_rational,
  insights_icon_weak_load,
  insights_icon_nplms,
  insights_popup_bgimg,
  deposit
};
export default exportimages;
