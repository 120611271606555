import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const topMerchantsAnalysisSlice = createApi({
  reducerPath: "analysis_top_merchants",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["top_merchants"],
  endpoints: (build) => ({
    getTopMerchantsSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        // url: `bank/${orgId}/dashboard?card=top_merchant&view=summary&page=0&size=10`,
        url:`/bank/${orgId}/dashboard/${payload.requid}/top_merchant/summary`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
    getTopMerchantsExpended: build.query({
      query: ({ orgId, headers, payload }) => ({
        // url: `bank/${orgId}/dashboard?card=top_merchant&view=expand&page=0&size=10`,
        url:`/bank/${orgId}/dashboard/${payload.requid}/top_merchant/expand`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetTopMerchantsSummaryQuery,
  useGetTopMerchantsExpendedQuery,
} = topMerchantsAnalysisSlice;
