import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONSUMERS, CONSUMERS_TYPES } from "../../../../utils/global";

export const secondaryConsumerAnalysisSlice = createApi({
  reducerPath: "analysis_secondary_consumer",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["secondary_consumer"],
  endpoints: (build) => ({
    getSecondaryConsumerSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        // url: `bank/${orgId}/dashboard?card=primary_consumer&view=summary&page=0&size=10`,
        url:`/bank/${orgId}/dashboard/${payload.requid}/primary_consumer/summary`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
      transformResponse: (response) => {
        if (response.status === "SUCCESS") {
          let res = [];
          for (let type of CONSUMERS) {
            const i = response?.data?.find((i) => i.keys === type.key);
            res.push({
              ...i,
              ...type,
            });
          }
          return { ...response, data: res };
        } else {
          return { ...response, data: null };
        }
      },
    }),

    getSecondaryConsumerExpended: build.query({
      query: ({ orgId, headers, payload }) => ({
        // url: `bank/${orgId}/dashboard?card=primary_consumer&view=expand&page=0&size=10`,
        url:`/bank/${orgId}/dashboard/${payload.requid}/primary_consumer/expand`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
      transformResponse: (response) => {
        if (response.status === "SUCCESS") {
          let res = [];
          for (let type of CONSUMERS_TYPES) {
            const i = response?.data?.find((i) => i.keys === type.key);
            res.push({
              ...i,
              ...type,
            });
          }
          return { ...response, data: res };
        } else {
          return { ...response, data: null };
        }
      },
    }),
  }),
});

export const {
  useGetSecondaryConsumerSummaryQuery,
  useGetSecondaryConsumerExpendedQuery,
} = secondaryConsumerAnalysisSlice;
