// import { useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import "./UpgradeCardPopUp.scss";
import exportimages from "../../../../assets/images/exportImages";
import { formatCategoryName } from "../../../Bank/report/widget/widgetsCommonFunction";
import { ContactSalesTeamModal } from "./ContactSalesTeam";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toastResponse } from "../../../Common/Toast/Toast";
import { toast } from "react-toastify";
import GetFromServer from "../../../../utils/GetFromServer";
import { useDispatch, useSelector } from "react-redux";
import { setUserContactedSales } from "../../../../Redux/authSlice";

export const UpgradeCardSpecificModal = (props) => {
  const [contactSalesModal, setContactSalesModal] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);
  const [currentToast, setCurrentToast] = useState("");
  const params = useParams();
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const userRole = authReducer.userData.role?.toLowerCase();
  // const subscriptionReducer = useSelector((state) => state.subscriptionReducer);
  const [loading, setLoading] = useState(false);
  // console.log(props)

  useEffect(() => {
    setSuccessFlag(false);
  }, [props]);

  const navigate = useNavigate();
  const showToast = (message, status) => {
    if (message !== currentToast) {
      if (status === "error") {
        toast.error(toastResponse("error", message));
      } else {
        toast.success(toastResponse("success", message));
      }
      setCurrentToast(message);
      setTimeout(() => setCurrentToast(""), 5500);
    }
  };

  const handleClick = async () => {
    if (successFlag) {
      props.onHide();
      let currentUrl = window.location.href;
      if (currentUrl.includes("/financial-health-score")) {
        navigate(`/bank/health-analysis/report/${params?.hid}`);
      } else if (currentUrl.includes("/drilldown/graph")) {
        navigate(`/bank/health-analysis/report/${params?.hid}`);
      }
    } else {
      let existing =
        props?.cardData?.name !== "upgrade_email_campaign" ? "Freemium" : "Pro";
      let involved =
        existing === "Freemium"
          ? "Pro"
          : existing === "Pro"
            ? "Marketing"
            : "Pro";
      setLoading(true);
      try {
        const response = await GetFromServer(
          `/client/pro/request?vFeature=${formatCategoryName(
            props?.cardData?.name,
          )}&vExistingTier=${existing}&vInvolvedTier=${involved}`,
          {},
        );
        if (response.data.status === "SUCCESS") {
          setSuccessFlag(true);
          // showToast("Email sent to sales team successfully.", "success");
          // props.onHide();
        }
      } catch (error) {
        showToast(error?.data?.message, "error");
      }
      // props.onHide();
      setLoading(false);
    }
  };
  return (
    <>
      <ContactSalesTeamModal
        show={contactSalesModal}
        cardData={props?.cardData}
        onHide={() => setContactSalesModal(false)}
      />
      <Modal
        {...props}
        size="lg"
        restoreFocus={"true"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="chamelonTourPopup"
      >
        <Modal.Header className="p-0 m-0 d-none" closeButton />
        <Modal.Body
          style={{ padding: "0px", position: "relative", overflow: "hidden" }}
          className="pt-0 m-0"
          closeButton
        >
          <img
            className="position-absolute cursor-pointer"
            style={{ right: "15px", top: "15px" }}
            src={exportimages.FU_cross}
            onClick={() => props.onHide()}
            alt=""
          />
          <div className="d-flex ">
            <div className="w-50">
              <img
                src={
                  successFlag
                    ? exportimages?.contact_sales_team_success_upgrade
                    : exportimages?.[`${props?.cardData?.src}_upgrade`]
                }
                style={{ left: "-0.5%", position: "relative", height: "100%" }}
                // className="p-9"
                alt=""
              />
            </div>

            <div
              className="d-flex align-items-start flex-column bd-highlight mb-3 p-12 p-xxl-9"
              // style="height: 200px;"
            >
              <div className="mb-auto p-2 bd-highlight">
                <h3
                  className="text-dark fw-bolder lh-lg mb-0"
                  style={{ fontSize: "1.5rem" }}
                >
                  {successFlag
                    ? "Sales Contacted Successfully"
                    : props?.cardData
                      ? formatCategoryName(props?.cardData?.title)
                      : "NA"}
                </h3>
                <div
                  className="text-muted fs-5 fs-3x-6 mt-8"
                  dangerouslySetInnerHTML={{
                    __html: successFlag
                      ? "<p>Your message has been successfully sent to our Sales team. They will be in touch with you shortly.</p><p className='mt-4'>Thank you for reaching out!</p>"
                      : props?.cardData
                        ? props?.cardData?.description
                        : "NA",
                  }}
                >
                  {/* {props?.cardData ? props?.cardData?.description : "NA"} */}
                </div>
              </div>
              <div
                className="p-2 bd-highlight w-100"
                style={{
                  display:
                    userRole === "reseller" || userRole === "bond-admin"
                      ? "none"
                      : "block",
                }}
              >
                {!successFlag && (
                  <button
                    type="button"
                    id="kt_login_password_reset_form_cancel_button "
                    // onClick={() => props.onHide()}
                    className="btn btn-other btn-bond px-8 py-3"
                    data-toggle="collapse"
                    href="#collapseTransactionBlock"
                    // role="button"
                    aria-expanded="false"
                    // disabled={loading}
                    aria-controls="collapseTransactionBlock"
                    onClick={() => {
                      if (!successFlag) {
                        dispatch(setUserContactedSales(true));
                      }
                      handleClick();
                      // props.onHide();
                      // setContactSalesModal(true);
                    }}
                    style={{ pointerEvents: loading ? "none" : "all" }}
                  >
                    <span
                      className="me-1 fs-5 fs-xxl-6"
                      style={{ fontWeight: "normal" }}
                    >
                      {successFlag ? "Dashboard" : "Contact Sales"}
                    </span>
                    <i
                      className="spinner-grow spinner-grow-sm ms-3"
                      id="btn_spinner"
                      role="status"
                      style={{ display: loading ? "inline-flex" : "none" }}
                    />
                  </button>
                )}
                {/* 
                <button
                  type="button"
                  id="take-a-tour"
                  className="btn btn-other-outline border-other btn-bond px-4 py-3 ms-4 w-50"
                  data-toggle="collapse"
                  href="#collapseTransactionBlock"
                  style={{
                    display:
                      props?.cardData?.name === "first_time_logged_in"
                        ? "inline-block"
                        : "none",
                  }}
                  aria-expanded="false"
                  aria-controls="collapseTransactionBlock"
                  onClick={() => props.onHide()}
                >
                  <span
                    className="me-1 fs-5 fs-xxl-6  "
                    style={{ fontWeight: "normal" }}
                  >
                    Take the Executive Tour
                  </span>
                </button> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
