import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  balance: {
    min: null,
    max: null,
  },
  product: [],
  persona: [],
  oppotype: [],
  zone: [],
  provider: [],
  productWithProvider: [],
  currentProduct: "",
  consumer: [],
  oppConsumers: [],
};
const selectedFilterSlice = createSlice({
  name: "selectedFilterSlice",
  initialState,
  reducers: {
    setFilterProducts(state, action) {
      state.product = action.payload;
    },
    setFilterProviders(state, action) {
      state.provider = action.payload;
    },
    setFilterproductWithProvider(state, action) {
      state.productWithProvider = action.payload;
    },
    setFilterZones(state, action) {
      state.zone = action.payload;
    },
    setFilterPersonas(state, action) {
      state.persona = action.payload;
    },
    setFilterOppoType(state, action) {
      state.oppotype = action.payload;
    },
    setFilterConsumers(state, action) {
      state.consumer = action.payload;
    },
    setFilterOpportunitiesConsumers(state, action) {
      state.oppConsumers = action.payload;
    },
    setFilterBalance(state, action) {
      state.balance.min = action.payload.min;
      state.balance.max = action.payload.max;
    },
    setCurrentProduct(state, action) {
      state.currentProduct = action.payload;
    },
    resetFilters(state, action) {
      state.product = [];
      state.provider = [];
      state.zone = [];
      state.persona = [];
      state.oppotype= [];
      state.currentProduct = "";
      state.productWithProvider = [];
      state.consumer = [];
      state.balance.min = null;
      state.balance.max = null;
      state.oppConsumers = [];
    },
  },
});

export const {
  setFilterProducts,
  setFilterproductWithProvider,
  setFilterProviders,
  setFilterZones,
  setFilterPersonas,
  setFilterOppoType,
  setCurrentProduct,
  setFilterBalance,
  setFilterConsumers,
  setFilterOpportunitiesConsumers,
  resetFilters,
} = selectedFilterSlice.actions;
export default selectedFilterSlice.reducer;
