import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BPM_KEY } from "../utils/global";

const initialState = {
  orguid: sessionStorage.getItem("selectedOrgId") ?? null,
  orgName: sessionStorage.getItem("selectedOrgName") ?? null,
  orgShortName: sessionStorage.getItem("selectedOrgName")
    ? (sessionStorage.getItem("selectedOrgName")?.split("-"))[0]
    : "",
  orgKey: null,
  healthAnalysisID: null,
  selectedFI: {},
  fiType: null,
  registeredFiList: [],
  allFiList: [],
};

export const updateSelectedFIThunk = createAsyncThunk(
  `updateSelectedFIThunk`,
  async ({ data }, thunkAPI) => {
    try {
      const org_name = data?.find(
        (i) => i.key === BPM_KEY.financial_institute,
      )?.display_name;
      const actualName = data?.find(
        (i) => i.key === BPM_KEY.financial_institute,
      )?.legal_name;
      const orguid = data?.find((i) => i.key === BPM_KEY.client_uid)?.value;
      const org_type = "";
      const orgClientId = data?.find(
        (i) => i.key === BPM_KEY.client_uid,
      )?.value;
      const orgKey = data?.find(
        (i) => i.key === BPM_KEY.financial_institute,
      )?.orgKey;
      const idrssd = data?.find(
        (i) => i.key === BPM_KEY.financial_institute,
      )?.idrssd;
      thunkAPI.dispatch(setOrgName(org_name));
      thunkAPI.dispatch(setOrgShortName(org_name));
      thunkAPI.dispatch(
        setSelectedFI({
          org_name: org_name,
          actualName: actualName,
          orguid: orguid,
          org_type: org_type,
          orgClientId: orgClientId,
          name: org_name,
          code: orgKey,
          idrssd: idrssd,
        }),
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const orgDetailsSlice = createSlice({
  name: "orgDetails",
  initialState,
  reducers: {
    setOrgId: (state, action) => {
      state.orguid = action.payload;
      sessionStorage.setItem("selectedOrgId", action.payload);
    },
    setOrgName: (state, action) => {
      state.orgName = action.payload;
    },
    setOrgShortName: (state, action) => {
      state.orgShortName = action.payload;
    },
    setOrgKey: (state, action) => {
      state.orgKey = action.payload;
    },
    setSelectedFI: (state, action) => {
      state.selectedFI = action.payload;
    },
    resetFIType: (state, action) => {
      state.fiType = action.payload;
    },
    setRegisteredFiList: (state, action) => {
      state.registeredFiList = action.payload;
    },
    setAllFiList: (state, action) => {
      state.allFiList = action.payload;
    },
    setHealthAnalysisID: (state, action) => {
      state.healthAnalysisID = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setOrgId,
  setOrgName,
  setOrgShortName,
  setOrgKey,
  setSelectedFI,
  resetFIType,
  setRegisteredFiList,
  setAllFiList,
  setHealthAnalysisID,
} = orgDetailsSlice.actions;

export default orgDetailsSlice.reducer;
