import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import { deleteAllCookies } from "../../utils/Utils";
import { toast } from "react-toastify";
import { toastResponse } from "../../Components/Common/Toast/Toast";
import { globalError } from "../../utils/global-error-handler";
import { setErrorType } from "../../Redux/errorSlice";

export const RTK_CONSTANT = {
  REFETCH_CARDS: false,
  REFETCH_TRENDS: true,
  POLLINF_INTERVAL: 1000 * 60 * 60,
};

const useFetch = (
  useQuery,
  { refetchOnMountOrArgChange, skip, selectFromResult },
) => {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const orgReducer = useSelector((state) => state?.orgReducer);
  const errorReducer = useSelector((state) => state?.errorReducer);
  const subscriptionReducer = useSelector((state) => state.subscriptionReducer);
  const orgId = orgReducer?.selectedFI?.orguid
    ? orgReducer?.selectedFI?.orguid
    : orgReducer?.selectedFI?.orgKey;
  const params = useParams();
  const subtype = subscriptionReducer?.isPro ? "default" : "trial";
  const navigate = useNavigate();
  const [errorCount400, setErrorCount400] = useState(0);

  const headers = {
    Authorization: `${authReducer?.userData?.tokenType?.toLowerCase()} ${
      authReducer?.userData?.token
    }`,
    "Content-Type": "application/json",
  };

  const payload = {
    filter: {},
    requid: params?.hid === "no-analysis-id" ? "" : params?.hid,
    gte: "",
    lte: "",
    duration: "",
  };

  const { data, isError, isLoading, error } = useQuery(
    {
      orgId,
      headers,
      payload,
      subtype,
    },
    selectFromResult !== undefined
      ? { refetchOnMountOrArgChange, skip, selectFromResult: selectFromResult }
      : orgId === ""
        ? {
            pollingInterval: RTK_CONSTANT.POLLINF_INTERVAL,
            refetchOnMountOrArgChange,
            skip: true,
          }
        : {
            pollingInterval: RTK_CONSTANT.POLLINF_INTERVAL,
            refetchOnMountOrArgChange,
            skip,
          },
  );

  if (isError) {
    if (error.status === 400) {
      if (errorCount400 === 0) {
        setErrorCount400(errorCount400 + 1);
        if (error?.data?.error?.includes("USER_NOT_FOUND")) {
          deleteAllCookies();
          window.history.pushState({}, "", "/user/clearsession");
          navigate("/user/login", { state: { directLogout: true } });
        } else {
          toast.error(
            toastResponse(
              "error",
              globalError({
                data: {
                  data: "",
                  error: error.status,
                  message: error?.error,
                  status: "ERROR",
                },
              }).message,
            ),
          );
        }
      }
    } else {
      if (!errorReducer?.errorType?.includes(error.status)) {
        dispatch(setErrorType(error.status));
        toast.error(
          toastResponse(
            "error",
            globalError({
              data: {
                data: "",
                error: error.status,
                message: error?.error,
                status: "ERROR",
              },
            }).message,
          ),
        );
      }
    }
  }

  return { data, isError, isLoading, error };
};
export default useFetch;
