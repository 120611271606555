import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { API_STATUS } from "../../sliceUtil";
import { _get, _post, _put } from "../../../../api/apiClient";
import { CustomError } from "../../../../api/CustomError";
import store from "../../../../app/store";

const initialSignUpState = {};

const _orgClientId = () => {
  const state = store.getState();
  return state?.authReducer?.userData?.client_User_Id;
};

export const validateEmailForFI = createAsyncThunk(
  "/auth/register",
  async (email, thunkAPI) => {
    const encodedMail = email.replace(/\+/g, "%2B").trim();
    try {
      const config = {
        successMessage: "Email domain validated.",
        errorMessage: "Something went wrong.",
      };
      const response = await _get(
        `registration/validate/domain?emailId=${encodedMail}`,
        {},
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw new CustomError(response, config);
      }
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const fetchInstutionsClientID = createAsyncThunk(
  "/auth/register",
  async (IDs, thunkAPI) => {
    try {
      const config = {
        successMessage: "Fetched client details",
        errorMessage: "Something went wrong.",
        disableToast: true,
      };
      const response = await _post(`registration/validate/client`, IDs, config);
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw new CustomError(response, config);
      }
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const activateUser = createAsyncThunk(
  "activateUser",
  async (userData, thunkAPI) => {
    try {
      const config = {
        successMessage: "User verified succssfully.",
        errorMessage: "Something went wrong.",
      };
      const encodedMail = userData?.emailId?.replace(/(\s|\+)/g, "%2B");
      const response = await _get(
        `/registration/verify?key=${userData?.key}&email_id=${encodedMail}`,
        {},
        config,
      );
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      return response;
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error?.error);
    }
  },
);
export const signUpNewUser = createAsyncThunk(
  "/auth/register",
  async (signUpObj, thunkAPI) => {
    try {
      const config = {
        successMessage: "User registered successfully.",
        errorMessage: "Failed to register user.",
        isShowToast: true,
      };
      const response = await _post(`registration`, signUpObj, config);
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      return response;
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error?.error);
    }
  },
);

export const getUserProfileDetailsById = createAsyncThunk(
  "/auth/userProfile",
  async ({ disableLoader }, thunkAPI) => {
    try {
      const config = {
        successMessage: "User Profile details fetched successfully.",
        errorMessage: "Failed to fetch user details.",
        isShowToast: true,
      };
      const response = await _get(`clientuser/${_orgClientId()}`, {}, config);
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      return response;
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error?.error);
    }
  },
);
export const changePassword = createAsyncThunk(
  "/auth/changePassword",
  async ({ userBody, disableLoader }, thunkAPI) => {
    try {
      const config = {
        successMessage: "Password changed successfully.",
        errorMessage: "Failed to change password.",
        isShowToast: true,
      };
      const response = await _put(`auth/changepassword`, userBody);
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      return response;
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error?.error);
    }
  },
);

export const resetPassword = createAsyncThunk(
  "/auth/resetPassword",
  async ({ emailId, disableLoader }, thunkAPI) => {
    try {
      const config = {
        successMessage: "Reset Password link sent on your registered Email ID.",
        errorMessage: "Failed to sent link.",
        isShowToast: true,
      };
      const encodedMail = emailId?.replace(/(\s|\+)/g, "%2B");
      const response = await _get(`/auth/resetpassword?email=${encodedMail}`);
      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }
      return response;
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error?.error);
    }
  },
);

export const signUpSlice = createSlice({
  name: "signUpNewUser",
  initialState: initialSignUpState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(signUpNewUser.pending, () => ({
        isLoading: API_STATUS.STATUS_LOADING,
      }))
      .addCase(signUpNewUser.fulfilled, (_state, action) => {
        return {
          isLoading: API_STATUS.STATUS_SUCCESS,
          signUpNewUser: action.payload,
        };
      })
      .addCase(signUpNewUser.rejected, (_state, action) => {
        return {
          isLoading: API_STATUS.STATUS_FAILURE,
          message: action.error.message || "Error while creating user",
          signUpNewUser: [],
        };
      });
  },
});

export const {} = signUpSlice.actions;
