import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GetFromServer from "../../../utils/GetFromServer";
import { setLoaderFalse } from "../../../Redux/loaderSlice";
import { _get } from "../../../api/apiClient";
import { CustomError } from "../../../api/CustomError";
import { CustomSuccess } from "../../../api/CustomSuccess";
import { HttpStatusCode } from "axios";

const initialState = {
  healthAnalysis: null,
  isSFTPFileUploading: false,
  currentHealthAnalysis: null,
};

export const fetchHealthAnalysisSlice = createAsyncThunk(
  `fetch_health_analysis`,
  async ({ orguid, disableToast }, thunkAPI) => {
    try {
      const config = {
        successMessage: "Fetched HealthAnalysis successfully.",
        errorMessage: "Failed to fetch HealthAnalysis!",
        disableToast: disableToast,
      };
      const response = await _get(
        `/bank/healthanalysis?orguid=${orguid}&type=none`,
      );

      if (response?.statusCode !== HttpStatusCode.Ok) {
        throw CustomError(response, config);
      }

      const { data, error, message, status, statusCode, responseType } =
        CustomSuccess(response, {
          ...config,
          disableToast: true,
        });

      return {
        data,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (err) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(err);
    }
  },
);

export const recursiveCallAnalysisStatus = createAsyncThunk(
  `recursive_call_analysis_stauts`,
  async ({ orguid, uid }, thunkAPI) => {
    try {
      const response = await GetFromServer(
        `/bank/healthanalysis/${uid}?orguid=${orguid}&lang=en_us&source=website`,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.data.error);
    }
  },
);

const healthAnalysisSlice = createSlice({
  name: "health_analysis",
  initialState,
  reducers: {
    setIsSFTPFileUploading: (state, action) => {
      state.isSFTPFileUploading = action.payload;
    },
    // Add the reset action to reset the state to initial values
    resetHealthAnalysis: (state) => {
      state.healthAnalysis = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHealthAnalysisSlice.fulfilled, (state, action) => {
        state.healthAnalysis = {
          ...state.healthAnalysis,
          payload: action.payload,
          isLoading: false,
        };
      })
      .addCase(fetchHealthAnalysisSlice.pending, (state, action) => {
        state.healthAnalysis = {
          isLoading: true,
        };
      })
      .addCase(recursiveCallAnalysisStatus.fulfilled, (state, action) => {
        state.currentHealthAnalysis = {
          ...state.currentHealthAnalysis,
          payload: action.payload,
          isLoading: false,
        };
      })
      .addCase(recursiveCallAnalysisStatus.pending, (state, action) => {
        state.currentHealthAnalysis = {
          isLoading: true,
        };
      });
  },
});

export const selectHealthAnalysis = (state) =>
  state?.healthAnalysisReducer?.healthAnalysis?.payload?.data;

export const selectIsSFTPFileUploading = (state) =>
  state?.healthAnalysisReducer?.isSFTPFileUploading;

export const { setIsSFTPFileUploading, resetHealthAnalysis } =
  healthAnalysisSlice.actions;

export default healthAnalysisSlice.reducer;
