import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const topPersonaAnalysisSlice = createApi({
  reducerPath: "analysis_top_persona",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["top_persona"],
  endpoints: (build) => ({
    getTopPersonaSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        // url: `bank/${orgId}/dashboard?card=persona&view=summary&page=0&size=10`,
        url:`/bank/${orgId}/dashboard/${payload.requid}/persona/summary`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
    getTopPersonaExpended: build.query({
      query: ({ orgId, headers, payload }) => ({
        // url: `bank/${orgId}/dashboard?card=persona&view=expand&page=0&size=10`,
        url:`/bank/${orgId}/dashboard/${payload.requid}/persona/expand`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const { useGetTopPersonaSummaryQuery, useGetTopPersonaExpendedQuery } =
  topPersonaAnalysisSlice;
