import axios from "axios";
import store from "../app/store";

const BASE_URL = process.env.REACT_APP_API_URL || "https://api.example.com";


const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(function (config) {
  const token = store.getState()?.authReducer?.userData?.token;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Define common API methods
const _get = async (url) => {
  return apiClient
    .get(url)
    .then((response) => {
      return {
        ...response,
        statusCode: response?.status,
        data: {
          ...response?.data,
          statusCode: response?.status,
        },
      };
    })
    .catch((error) => {
      return error?.response?.data?.error
        ? { ...error?.response?.data, statusCode: error?.response?.status }
        : {
            data: error?.response?.data,
            error: error?.code,
            message: error?.message,
            status: error?.response?.statusText,
            statusCode: error?.response?.status,
          };
    });
};

const _post = async (url, data = {}) => {
  return apiClient
    .post(url, data)
    .then((response) => {
      return {
        ...response,
        statusCode: response?.status,
        data: {
          ...response?.data,
          statusCode: response?.status,
        },
      };
    })
    .catch((error) => {
      return error?.response?.data?.error
        ? { ...error?.response?.data, statusCode: error?.response?.status }
        : {
            data: error?.response?.data,
            error: error?.code,
            message: error?.message,
            status: error?.response?.statusText,
            statusCode: error?.response?.status,
          };
    });
};

const _put = async (url, data = {}) => {
  return apiClient
    .put(url, data)
    .then((response) => {
      return {
        ...response,
        statusCode: response?.status,
        data: {
          ...response?.data,
          statusCode: response?.status,
        },
      };
    })
    .catch((error) => {
      return error?.response?.data?.error
        ? { ...error?.response?.data, statusCode: error?.response?.status }
        : {
            data: error?.response?.data,
            error: error?.code,
            message: error?.message,
            status: error?.response?.statusText,
            statusCode: error?.response?.status,
          };
    });
};

const _delete = async (url) => {
  return apiClient
    .delete(url)
    .then((response) => {
      return {
        ...response,
        statusCode: response?.status,
        data: {
          ...response?.data,
          statusCode: response?.status,
        },
      };
    })
    .catch((error) => {
      return error?.response?.data?.error
        ? { ...error?.response?.data, statusCode: error?.response?.status }
        : {
            data: error?.response?.data,
            error: error?.code,
            message: error?.message,
            status: error?.response?.statusText,
            statusCode: error?.response?.status,
          };
    });
};

export { _get, _post, _put, _delete };
