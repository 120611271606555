import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const topCompetitorsAnalysisSlice = createApi({
  reducerPath: "analysis_top_competitors",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["top_competitors"],
  endpoints: (build) => ({
    getTopCompetitorsSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        // url: `bank/${orgId}/dashboard?card=top_competitor&view=summary&page=0&size=10`,
        url:`/bank/${orgId}/dashboard/${payload.requid}/top_competitor/summary`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
    getTopCompetitorsExpended: build.query({
      query: ({ orgId, headers, payload }) => ({
        // url: `bank/${orgId}/dashboard?card=top_competitor&view=expand&page=0&size=10`,
        url:`/bank/${orgId}/dashboard/${payload.requid}/top_competitor/expand`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetTopCompetitorsSummaryQuery,
  useGetTopCompetitorsExpendedQuery,
} = topCompetitorsAnalysisSlice;
