import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../../../app/store";
import { HttpStatusCode } from "axios";
import { CustomError } from "../../../api/CustomError";
import { _get } from "../../../api/apiClient";
import { CustomSuccess } from "../../../api/CustomSuccess";
import { setLoaderFalse, setLoaderTrue } from "../../../Redux/loaderSlice";
import { API_STATUS } from "../sliceUtil";
import { BANK_PERFORMANCE_METRICS, USER_TYPE } from "../../../utils/global";
import { calculatePercentage } from "../../../utils/Utils";
import { updateSelectedFIThunk } from "../../../Redux/orgDetailsSlice";

const _userRole = () => {
  const state = store.getState();
  return state?.authReducer?.userData?.role;
};

const _orgClientId = () => {
  const state = store.getState();
  return state?.orgReducer?.selectedFI?.orgClientId;
};

const _selectedIdrssd = () => {
  const state = store.getState();
  return state?.orgReducer?.selectedFI?.idrssd;
};

const fnFhsData = (data) => {
  const groupedData = {};
  const ungroupedData = {};
  const financialInstituteData = {};
  const keysToIncludeUngroupedData = [
    "net_interest_income",
    "total_loans",
    "total_deposits",
  ];

  for (const key in data) {
    if (data[key]?.group !== undefined && data[key]?.order !== undefined) {
      groupedData[key] = data[key];

      if (keysToIncludeUngroupedData?.includes(data[key]?.key)) {
        ungroupedData[key] = data[key];
      }
    } else {
      if (key === "financial_institute") {
        financialInstituteData[key] = data[key];
      } else {
        ungroupedData[key] = data[key];
      }
    }
  }
  return {
    ...financialInstituteData,
    groupedData: Object?.values(groupedData),
    ungroupedData: ungroupedData,
  };
};

const separateGrouping = (data) => {
  const groupMapping = {
    1: "segment_influencing",
    2: "all_segments",
  };
  return data.reduce((acc, obj) => {
    const key = groupMapping[obj?.group];
    const curGroup = acc[key] ?? [];
    return { ...acc, [key]: [...curGroup, obj] };
  }, {});
};

function calculateTrendPercentageForUngroupedData(data) {
  const obj = {};
  for (const key in data) {
    if (
      data[key] !== null &&
      typeof data[key] === "object" &&
      !Array.isArray(data[key])
    ) {
      obj[key] = {
        ...data[key],
        forcasted_trend: calculatePercentage(data[key]),
      };
    }
  }
  return { ...data, ...obj };
}

const transformArray = (array) =>
  array?.data?.data?.records?.map((item) => {
    const result = fnFhsData(item);
    const groupedResult = separateGrouping(result?.groupedData);

    const calculatedPercentageResultForUngroupedData =
      calculateTrendPercentageForUngroupedData(result?.ungroupedData);
    const { groupedData, ungroupedData, financial_institute, ...rest } = result;

    return {
      ...financial_institute,
      groupedResult: groupedResult,
      ungroupedResult: calculatedPercentageResultForUngroupedData,
    };
  });

const initInfiniteBankPerformanceMetricsState = {
  data: null,
  isLoading: API_STATUS.STATUS_IDLE,
};

export const bankPerformanceMertics_data_modeling = createAsyncThunk(
  "bankPerformanceMertics_data_modeling",
  async ({ bpm_data }, thunkAPI) => {
    try {
      const bankPerformanceMertics = await bpm_data?.map((item) => {
        return Object.entries(item)
          .filter(([key, value]) =>
            BANK_PERFORMANCE_METRICS.map((item) => item.key).includes(key),
          )
          .map(([key, value]) =>
            typeof value === "string" || value === null
              ? {
                  ...BANK_PERFORMANCE_METRICS.find((i) => i.key === key),
                  key: key,
                  value: value,
                }
              : {
                  ...value,
                  ...BANK_PERFORMANCE_METRICS.find((i) => i.key === key),
                },
          )
          .sort((a, b) => {
            const indexA = BANK_PERFORMANCE_METRICS.map(
              (item) => item.key,
            ).indexOf(a.key);
            const indexB = BANK_PERFORMANCE_METRICS.map(
              (item) => item.key,
            ).indexOf(b.key);
            return indexA - indexB;
          });
      });
      return {
        bankPerformanceMertics: bankPerformanceMertics?.map((item) => {
          const temp = [];
          item.forEach((i) => {
            if (i.colspan === 1) {
              temp.push(i);
            } else {
              temp.push(i);
              temp.push({ ...i, key: i.key + "_next" });
            }
          });
          return temp;
        }),
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const fetchInfiniteBankPerformanceMetricsData = createAsyncThunk(
  "fetchInfiniteBankPerformanceMetricsData",
  async ({ paginationObj }, thunkAPI) => {
    try {
      // if (paginationObj?.currentPage === 1) thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Fetched BPM data successfully.",
        errorMessage: "Failed to fetch BPM data!",
      };
      const _pageNumber = paginationObj?.currentPage
        ? `page=${paginationObj?.currentPage}`
        : "";
      const _pageSize =
        paginationObj?.limit > 0 ? `&size=${paginationObj?.limit}` : "";
      const _searchBy = paginationObj?.searchData
        ? `&searchby=${paginationObj?.searchData}`
        : "";
      const _sortBy = paginationObj?.sortObj?.sortField
        ? `&sortby=${paginationObj?.sortObj?.sortField?.replace(/_next$/, "")}`
        : "";
      const _orderBy = paginationObj?.sortObj?.sortOrder
        ? paginationObj?.sortObj?.sortOrder === 1
          ? "&orderby=desc"
          : "&orderby=asc"
        : "";
      const _sortByValue =
        paginationObj?.sortObj?.sortField &&
        paginationObj?.sortObj?.sortField?.includes("_next")
          ? `&sortby_value=forecasted`
          : `&sortby_value=current`;

      const response = await _get(
        `bank/${_orgClientId()}/performance?${_pageNumber}${_pageSize}${_searchBy}${_sortBy}${_orderBy}${_sortByValue}`,
      );

      if (response?.data?.statusCode !== HttpStatusCode.Ok) {
        throw new Error(CustomError(response, config)?.message);
      }

      const { data, error, message, status, statusCode, responseType } =
        CustomSuccess(response, {
          ...config,
          disableToast: true,
        });

      const bankPerformanceMertics = await thunkAPI.dispatch(
        bankPerformanceMertics_data_modeling({
          bpm_data: data?.records,
        }),
      );

      thunkAPI.dispatch(setLoaderFalse());
      return {
        ...data,
        bankPerformanceMertics:
          bankPerformanceMertics?.payload?.bankPerformanceMertics,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getFHSDataByIdrssd = createAsyncThunk(
  "bankPerformanceMertics",
  async ({ idrssd }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoaderTrue());
      const config = {
        successMessage: "Fetched data successfully.",
        errorMessage: "Failed to fetch data!",
      };

      const response = await _get(
        `bank/${_orgClientId()}/performance/${idrssd ? idrssd : _selectedIdrssd()}`,
      );

      if (response?.data?.statusCode !== HttpStatusCode.Ok) {
        throw new Error(CustomError(response, config)?.message);
      }
      const bankPerformanceMertics = await thunkAPI.dispatch(
        bankPerformanceMertics_data_modeling({
          bpm_data: response?.data?.data?.records,
        }),
      );

      if (_userRole() !== USER_TYPE.ADMIN && _userRole() !== USER_TYPE.USER) {
        await thunkAPI.dispatch(
          updateSelectedFIThunk({
            data: bankPerformanceMertics?.payload?.bankPerformanceMertics[0],
          }),
        );
      }

      const FIData = transformArray(response);

      const { data, error, message, status, statusCode, responseType } =
        CustomSuccess(response, {
          ...config,
          disableToast: true,
        });

      thunkAPI.dispatch(setLoaderFalse());
      return {
        FIData,
        error,
        message,
        status,
        statusCode,
        responseType,
      };
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const infiniteBankPerformanceMetricsSlice = createSlice({
  name: "infiniteBankPerformanceMetricsSlice",
  initialState: initInfiniteBankPerformanceMetricsState,
  reducers: {
    updateIdrssd(state, action) {
      state.idrssd = action.payload;
    },
    setSelectedBankMetricData(state, action) {
      state.selectedBankMetricData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfiniteBankPerformanceMetricsData.pending, (_state) => ({
        ..._state,
        isLoading: API_STATUS.STATUS_LOADING,
      }))
      .addCase(
        fetchInfiniteBankPerformanceMetricsData.fulfilled,
        (_state, _action) => {
          return {
            ..._state,
            data: { ..._action.payload },
            isLoading: API_STATUS.STATUS_SUCCESS,
          };
        },
      )
      .addCase(
        fetchInfiniteBankPerformanceMetricsData.rejected,
        (_state, action) => ({
          isLoading: API_STATUS.STATUS_FAILURE,
          message: action.error.message || "Error while fetching data",
        }),
      );
  },
});

export const selectBpmData = (_state) =>
  _state?.infiniteBankPerformanceMetricsReducer;

export const { updateIdrssd, setSelectedBankMetricData } =
  infiniteBankPerformanceMetricsSlice.actions;
