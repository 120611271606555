import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const opportunitiesAnalysisSlice = createApi({
  reducerPath: "analysis_opportunities",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["opportunities"],
  endpoints: (build) => ({
    getOpportunitiesSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        // url: `bank/${orgId}/dashboard?card=opportunities&view=summary&page=0&size=10`,
        url:`/bank/${orgId}/dashboard/${payload.requid}/opportunities/summary`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),

    getOpportunitiesExpended: build.query({
      query: ({ orgId, headers, payload }) => ({
        // url: `bank/${orgId}/dashboard?card=opportunities&view=expand&page=0&size=10`,
        url:`/bank/${orgId}/dashboard/${payload.requid}/opportunities/expand`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetOpportunitiesSummaryQuery,
  useGetOpportunitiesExpendedQuery,
} = opportunitiesAnalysisSlice;
